.sidebar_overflow::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #14244b;
}
